import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import ElementUI from 'element-ui'
import './assets/style/main.css'
import './assets/style/grid.less'
import './plugins/element.js'
import i18n from './plugins/i18n'

Vue.config.productionTip = false

Vue.use(ElementUI)

new Vue({
  i18n,
  render: (h) => h(App)
}).$mount('#app')
