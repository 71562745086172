import axios from 'axios'
import { i18n } from '@/plugins/i18n'
import { Message } from 'element-ui'

let errorMsg = (response) => {
  let errorMsgKey = ''
  if (response.data && response.data.code) {
    errorMsgKey = response.data.code
  } else {
    errorMsgKey = response.status || 'defaultErrorMessage'
  }
  return i18n.t(`errorMessages.${errorMsgKey}`)
}

axios.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    Message({
      showClose: false,
      message: errorMsg(err.response),
      type: 'error',
      center: true
    })

    return Promise.reject(err.response)
  }
)
const del = axios.delete
const { get, post, put } = axios

export { get, post, put, del }
