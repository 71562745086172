import Vue from 'vue'
import VueI18n from 'vue-i18n'

import enLocale from 'element-ui/lib/locale/lang/en'
import cnLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'cn',
  messages: {
    cn: Object.assign(require('@/assets/languages/messages_cn.json'), cnLocale),
    en: Object.assign(require('@/assets/languages/messages_en.json'), enLocale)
  }
})
