<template>
  <div class="homepage">
    <div class="header pull-center-xs-only">
      <div class="logo pull-left-sm-and-up mr64-sm-and-up">
        <a href="/">
          <img src="../assets/images/logo.png" />
        </a>
      </div>
      <div class="nav">
        <div class="nav-item pull-left-sm-and-up"><a href="#product">产品</a></div>
        <div class="nav-item pull-left-sm-and-up"><a href="#solution">解决方案</a></div>
        <div class="nav-item pull-right-sm-and-up"><a href="#contact">联系我们</a></div>
      </div>
    </div>
    <div class="main">
      <div class="banner">
        <img src="../assets/images/banner.png" />
        <div class="content">
          <div class="title">城市楼宇能耗监测系统</div>
          <div class="sub-title">推动实现绿色低碳楼宇、助力碳达峰碳中和</div>
        </div>
      </div>
      <div id="product" class="container product grid-container">
        <el-row>
          <el-col :md="8" class="product-part">
            <img src="../assets/images/product/realtime-collect.png" alt="" />
            <div class="title">实时数据采集</div>
            <div class="description">
              管理楼宇用能线路拓扑，通过物联网设备采集楼宇内的大型用电设备、房间、企业等用电单元的实时用电数据，保存至数据库。
            </div>
          </el-col>
          <el-col :md="8" class="product-part">
            <img src="../assets/images/product/analysis.png" alt="" />
            <div class="title">用能全景分析</div>
            <div class="description">
              多维度统计分析楼宇用能情况，建立分项计量和支路用能模型，生成同环比曲线，统计昼夜和峰平谷能耗占比。
            </div>
          </el-col>
          <el-col :md="8" class="product-part">
            <img src="../assets/images/product/doctor-and-suggest.png" alt="" />
            <div class="title">节能诊断和建议</div>
            <div class="description">
              智能诊断用电异常并发送报警，实时监测各用能支路的线损情况，并对楼宇能效进行测评。
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="solution" class="container solution grid-container">
        <el-row>
          <el-col :md="12">
            <div class="solution-part">
              <div class="content">
                <img src="../assets/images/solution/area.png" alt="" />
                <div class="mask">
                  <p>
                    实时监测城市辖区内的所有大型公共建筑能耗情况，同环比分析当前能耗与历史能耗，了解用能趋势，多建筑类型分析对比、分项计量、多楼用能对比、峰平谷分析、楼宇用电排行榜，为城市管理者打造低碳绿色城区提供数据支撑。
                  </p>
                </div>
              </div>
              <div class="title">区域公共建筑能耗监测解决方案</div>
            </div>
          </el-col>
          <el-col :md="12">
            <div class="solution-part">
              <div class="content">
                <img src="../assets/images/solution/building.png" alt="" />
                <div class="mask">
                  <p>
                    为城市大型建筑和企业总部大楼提供实时用能数据采集，全景式统计分析，随时掌握大楼内的公共用电设施、各办公空间、入驻企业的实时用能情况和趋势，帮助楼宇管理者提高楼宇能效，降低用能成本，打造节能低碳型绿色楼宇。
                  </p>
                </div>
              </div>
              <div class="title">城市楼宇低碳节能解决方案</div>
            </div>
          </el-col>
          <el-col :md="12">
            <div class="solution-part">
              <div class="content">
                <img src="../assets/images/solution/park.png" alt="" />
                <div class="mask">
                  <p>
                    管理园区内多栋楼宇，通过无线物联网实时采集用能数据。远程抄表功能可自动为园区内的入驻企业生成每月电费账单和每日用量详单。多维度分析报表，让园区管理者一目了然掌握各楼栋以及各入驻企业的用能情况。
                  </p>
                </div>
              </div>
              <div class="title">智慧园区能耗监测解决方案</div>
            </div>
          </el-col>
          <el-col :md="12">
            <div class="solution-part">
              <div class="content">
                <img src="../assets/images/solution/school.png" alt="" />
                <div class="mask">
                  <p>
                    针对校园内多栋不同用途建筑，无线物联网实时采集多楼栋用能数据，智能排查用电异常设备，统计线路损耗，分析校园用电模型，为打造绿色低碳校园提供支撑。
                  </p>
                </div>
              </div>
              <div class="title">智慧校园能耗监测解决方案</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="contact" class="container contact">
        <div class="contact-background"></div>
        <div class="contact-form">
          <!-- <el-row> -->
          <div class="contact-title hidden-sm-and-down">
            <img src="../assets/images/logo.png" alt="" />
            <p>
              城市楼宇 <br />
              能耗监测系统
            </p>
          </div>
          <div class="contact-body pull-center-sm-and-down">
            <div class="title">联系我们了解更多详细信息</div>
            <div class="content">
              <div class="form full-width-xs-only">
                <el-form
                  ref="form"
                  :model="inquiry"
                  label-width="110px"
                  label-position="left"
                  size="small"
                  :rules="rules"
                >
                  <el-form-item label="您的单位名称" prop="orgName" class="form-item">
                    <el-input v-model="inquiry.orgName"></el-input>
                  </el-form-item>
                  <el-form-item label="您的姓名" prop="contact" class="form-item">
                    <el-input v-model="inquiry.contact"></el-input>
                  </el-form-item>
                  <el-form-item label="您的手机号码" prop="phone" class="form-item">
                    <el-input v-model="inquiry.phone"></el-input>
                  </el-form-item>
                  <el-form-item label="手机验证码" prop="verificationCode" class="form-item">
                    <el-input v-model="inquiry.verificationCode" style="width: 45%"></el-input>
                    <el-button
                      icon="el-icon-mobile-phone"
                      @click="send"
                      style="width: 40%; float: right; min-width: 100px"
                      :disabled="(disabled = !show)"
                    >
                      <span v-show="show">获取验证码</span>
                      <span v-show="!show" class="count">{{ count }} s</span>
                    </el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      :loading="submitting"
                      @click="onSubmit"
                      style="
                        float: right;
                        width: 100%;
                        border-radius: 16px 16px 16px 16px;
                        background-color: rgba(5, 63, 130, 100);
                        font-size: 14px;
                      "
                      >提交</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
              <div class="qrcode hidden-xs-only">
                <img src="../assets/images/contact/qrcode.png" alt="" />
                <p>微信扫码联系我们了解更多</p>
              </div>
            </div>
          </div>
          <!-- </el-row> -->
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="copyright">
        <p>版权所有 © 杭州拾米网络科技有限公司</p>
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank"> 浙ICP备 18029023号-7</a>
        </p>
      </div>
      <div class="address">
        <i class="el-icon-location">杭州市滨江区东信大道66号东方通信科技园4号楼2楼</i>
      </div>
    </div>
  </div>
</template>

<script>
const TIME_COUNT = 60
import { smsApi } from '@/api/sms'
import { inquiryApi } from '@/api/inquiry'
import 'element-ui/lib/theme-chalk/display.css'

export default {
  name: 'Homepage',
  data() {
    return {
      inquiry: {},
      show: true /* 布尔值，通过v-show控制显示‘获取按钮’还是‘倒计时’ */,
      count: '' /* 倒计时 计数器 */,
      timer: null,
      submitting: false,
      rules: {
        orgName: [{ required: true, message: '请输入您的单位名称', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入您的手机号码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!this.validataPhone(value)) {
                return callback(new Error('手机号输入错误'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        verificationCode: [
          { required: true, message: '短信验证码不能为空', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^\d{6}$/.test(value)) {
                return callback(new Error('验证码为6位数字'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    send() {
      if (!this.timer) {
        if (!this.validataPhone(this.inquiry.phone)) {
          this.$refs['form'].validateField('phone')
          return
        }
        smsApi.sendVerificationCode(this.inquiry.phone).then((res) => {
          this.count = TIME_COUNT
          this.show = false
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
          this.$message({
            type: 'success',
            message: `短信验证码已经发送，5分钟内有效`,
            center: true
          })
        })
      }
    },
    validataPhone(phone) {
      if (/^(?:(?:\+|00)86)?1\d{10}$/.test(phone)) {
        return true
      }

      return false
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.submitting = true

          inquiryApi
            .create(this.inquiry)
            .then((res) => {
              if (res == null) {
                console.log(res)
              } else {
                this.$message({
                  type: 'success',
                  center: true,
                  message: `信息提交成功，我们的技术专家会尽快与您联系`
                })
                this.$refs['form'].resetFields()
              }
              this.submitting = false
            })
            .catch((err) => {
              this.submitting = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.homepage {
  .header {
    max-width: 1400px;
    margin: auto;
    padding: 0 50px;
    .logo {
      img {
        margin-top: 20px;
        width: 142px;
      }
    }
    .nav {
      .nav-item {
        padding: 0 30px;
        display: inline-block;
        a {
          text-decoration: inherit;
          line-height: 80px;
          font-size: 16px;
          color: rgba(16, 16, 16, 100);
        }
      }
    }
  }
  .main {
    .banner {
      position: relative;
      width: 100%;
      height: 638px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .content {
        position: absolute;
        top: 35px;
        left: 4%;
        color: rgba(255, 255, 255, 100);
        .title {
          font-size: 48px;
          font-weight: bold;
        }
        .sub-title {
          font-size: 20px;
        }
      }
    }
    .container {
      margin: 80px auto;
    }
    .product {
      text-align: center;
      padding-top: 158px;
      .product-part {
        padding: 40px 50px;
        vertical-align: bottom;

        display: inline-block;
        img {
          width: 142px;
          height: 116px;
          opacity: 0.38;
          object-fit: cover;
          margin-bottom: 54px;
        }

        .title {
          color: rgba(16, 16, 16, 100);
          font-size: 28px;
          margin-bottom: 32px;
        }
        .description {
          text-align: justify;
          font-size: 18px;
          height: 75px;
          color: rgba(115, 112, 112, 100);
        }
      }
    }
    .solution {
      margin-top: 158px;
      .solution-part {
        text-align: center;
        margin: 35px 4%;
        // padding: 35px 4%;

        .content {
          height: 278px;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            background: rgba(0, 0, 0, 100);
            font-size: 18px;
            color: #ffffff;
            visibility: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              width: 80%;
              text-align: left;
            }
          }
        }

        .title {
          color: rgba(6, 0, 0, 100);
          font-size: 28px;
          margin-top: 15px;
        }

        &:hover {
          .mask {
            visibility: visible;
          }
        }
      }
    }
    .contact {
      margin-top: 160px;
      position: relative;
      .contact-background {
        background-image: url('../assets/images/contact/background.png');
        background-size: cover;
        width: 100%;
        height: 630px;
        opacity: 0.6;
      }
      .contact-form {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 952px;
        width: 100%;
        transform: translate(-50%, -50%);
        height: 336px;
        display: flex;
        justify-content: space-between;

        .contact-title {
          width: 30%;
          text-align: center;
          color: rgba(5, 63, 130, 100);
          background-color: rgba(5, 63, 130, 100);
          opacity: 0.85;
          img {
            width: 150px;
            // height: 44px;
            margin-top: 58px;
          }
          p {
            font-size: 28px;
            color: rgba(255, 255, 255, 100);
            text-align: center;
            font-family: SourceHanSansSC-bold;
            margin-top: 36px;
          }
        }
        .contact-body {
          width: 70%;
          background-color: #ffffffc4;
          padding: 20px 40px;

          .title {
            font-size: 20px;
            font-weight: bold;
            padding-bottom: 20px;
          }
          .content {
            display: flex;
            justify-content: space-between;

            .form {
              width: 60%;
              text-align: left;
              .form-item {
                ::v-deep label {
                  color: rgba(16, 16, 16, 100);
                  font-size: 14px;
                }
              }
            }
            .qrcode {
              width: 35%;
              text-align: center;

              img {
                width: 162px;
                height: 162px;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    margin-bottom: 20px;
    text-align: center;
    .copyright {
      p {
        display: inline-block;
        margin: 20px 20px;
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        a {
          text-decoration: inherit;
          color: rgba(16, 16, 16, 100);
        }
      }
    }
    .address {
      color: rgba(166, 162, 162, 100);
      font-size: 12px;
    }
  }
}
</style>
